import { PDFDocument } from "pdf-lib";
import moment, { Moment } from "moment";

// PrintList API Service
import {
  ControllerType,
  GetAdmitOrder,
  GetBillEncounterSummary,
  GetCudentMedCertInfo,
  GetDiagnosisMedRecordDetail,
  GetDoctorDetail,
  GetDoctorInfoFromEncounter,
  GetHospitalName,
  GetInvoiceItemList,
  GetIpdMedBill,
  GetOperatingOrderDetail,
  GetPatientAllergy,
  GetPreName,
  GetTreatmentDetail,
} from "./CardPrintListApiService";
import { beStringToAdString, beToAd } from "react-lib/utils/dateUtils";

/**========================================================================
 **                              CONSTANTS
 *========================================================================**/

const LANGUAGE_USAGE = {
  TH: "TH",
  EN: "EN",
};

// อ้างจาก CardPatientInfo
const MARRIAGE_STATUS = [
  { id: "S", text: "โสด" },
  { id: "M", text: "สมรส" },
  { id: "E", text: "หมั้น" },
  { id: "D", text: "หย่า" },
  { id: "W", text: "หม้าย" },
  { id: "A", text: "แยกกันอยู่" },
] as { id: string; text: string }[];

// อ้างจาก core Patient
export const GENDER_LIST = {
  M: { name_th: "ชาย", name_en: "Male" },
  F: { name_th: "หญิง", name_en: "Female" },
  U: { name_th: "ไม่แน่ชัด", name_en: "Undetermined" },
  PM: { name_th: "Male (Not Approved)", name_en: "Male (Not Approved)" },
  PF: { name_th: "Female (Not Approved)", name_en: "Female (Not Approved)" },
  MM: { name_th: "Men who have sex with men", name_en: "Men who have sex with men" },
  TG: { name_th: "Transgender", name_en: "Transgender" },
} as Record<string, { name_th: string; name_en: string }>;

/**========================================================================
 **                              UTILS
 *========================================================================**/

/**
 * For Merge the PDFs By Using Uint8Array
 * @param pdfBuffers - ให้แปลง pdf เป็น Uint8Array ก่อน
 * @returns
 */
export const MergePdfs = async (pdfBuffers: Uint8Array[]) => {
  const pdfMerged = await PDFDocument.create();

  for (const pdfBuffer of pdfBuffers) {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const pages = await pdfMerged.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages.forEach((page) => pdfMerged.addPage(page));
  }

  const mergedPdfBytes = await pdfMerged.save();
  const getAsBase64 = await pdfMerged.saveAsBase64();
  const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  const modifiedPdfUrl = URL.createObjectURL(blob);

  return {
    uint8Array: mergedPdfBytes,
    blobUrl: modifiedPdfUrl,
    blobRaw: blob,
    base64String: getAsBase64,
  };
};

/**
 * สำหรับ Slide Date Time ในการแสดงวันที่พิมพ์เอกสาร
 * @param date - Format Support: `"dd/mm/yyyy"` และ `"dd/mm/yyyy, hh:mm"`
 * @param locale - สำหรับแสดงชื่อเดือน (ex: "th" เดือน 2 จะเป็น "กุมภาพันธ์")
 * @returns [0] = Day, [1] = Month, [2] = Month Name, [3] = Year, [4] = Time
 */
export const GetSlideDate = (date: string, locale: string = "th") => {
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})(?:,\s*(\d{2}:\d{2}))?$/;
  const match = date.match(dateRegex);

  if (!match) return [null, null, null, null, null];

  const [, getDay, getMonth, getYear, getTime] = match;
  const getMonthName = moment(`${getMonth}`, "MM").locale(locale).format("MMMM");

  // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year, [4] = Time
  return [getDay, getMonth, getMonthName, getYear, getTime || null];
};

/**
 * คำนวณอายุ สำหรับนำไปแสดงในเอกสาร (ที่มีการแยก อายุปี เดือน วัน)
 * @param birthDate - Format must using `"beToAd()"` from `"react-lib/utils/dateUtils"` first
 * @param currentDate - Format must using `"beToAd()"` from `"react-lib/utils/dateUtils"` first
 * @returns [0] = Age Year, [1] = Age Month, [2] = Age Day
 */
export const CalculateAge = (birthDate: Moment | undefined, currentDate: Moment | undefined) => {
  if (!birthDate && !currentDate) return [null, null, null];

  const diffDate = moment(currentDate).diff(birthDate);
  var yearDuration = moment.duration(diffDate).years();
  var monthDuration = moment.duration(diffDate).months();
  var dayDuration = moment.duration(diffDate).days();

  return [yearDuration, monthDuration, dayDuration];
};

/**
 * ใช้สำหรับ Validate Object
 * @param obj - Object
 * @returns
 */
export const IsEmptyObject = (obj: any) => !obj || Object.keys(obj).length === 0;

/**
 * ใช้สำหรับ Validate Array
 * @param arr - Array
 * @returns
 */
export const GetArrayorEmpty = (arr: string[] | null | undefined): string[] => arr ?? [];

/**
 * ใช้สำหรับ Validate Value ถ้าไม่มีจะแสดงเป็น `" "` (default) ในเอกสาร
 * @param value - Value as `string` or `null`
 * @param defaultValue
 * @returns
 */
export const GetValueOrDefault = (value: string | null | undefined, defaultValue: string = " ") =>
  value?.trim() ?? defaultValue;

/**
 * แสดงคำนำหน้าจาก id (Mapping)
 * @param prenameList - Prename List (from API)
 * @param id - Prename's id User or Patient
 * @returns
 */
export const GetPreNameFromId = (prenameList: any[], id: string | number) => {
  if (!prenameList && id) return "";

  // console.log("[Print List Common] Prename List: ", { prenameList, id });

  const existsItem = prenameList.find((item: any) => item.id === id);

  return existsItem ? existsItem.name : "";
};

/**
 * Format ที่อยู่ของผู้ป่วย
 * @param propsPatientInfo - props from CardPrintList (`props.patientInfo`)
 * @returns
 */
export const GetFormatAddress = (propsPatientInfo: any) => {
  const address = propsPatientInfo?.present_address ?? {};

  return {
    haveAddress: IsEmptyObject(address),
    no: GetValueOrDefault(address.no),
    town: GetValueOrDefault(address.town),
    name: GetValueOrDefault(address.name),
    street: GetValueOrDefault(address.street),
    road: GetValueOrDefault(address.road),
    city: GetValueOrDefault(address.city_label),
    district: GetValueOrDefault(address.district_label),
    province: GetValueOrDefault(address.province_label),
    zipcode: GetValueOrDefault(address.zipcode),
  };
};

/**
 * ใช้แบ่งวันที่
 * @param dateStr
 * @param isAdmit
 * @param isAd - แสดงเป็น ค.ศ. หรือไม่
 * @param noTime - ให้แสดงเวลาด้วยหรือไม่
 * @returns
 */
const ParseDate = (dateStr: string, isAdmit: boolean, isAd: boolean, noTime: boolean): string => {
  const [datePart, timePart] = dateStr.split(" [");
  const [day, month, year] = datePart.split("/");
  const [hrs, min] = timePart.slice(0, -1).split(":");

  let formattedYear = year;

  if (isAd) {
    formattedYear = isAdmit ? year : (parseInt(year) - 543).toString();
  } else {
    formattedYear = isAdmit ? (parseInt(year) + 543).toString() : year;
  }

  if (noTime) {
    return `${day}/${month}/${formattedYear}`;
  } else {
    return `${day}/${month}/${formattedYear} [${hrs}:${min}]`;
  }
};

/**
 * Format Visit Date ของ Encounter ของผู้ป่วย
 * @param admitDate
 * @param encounterDate
 * @param isAd - แสดงเป็น ค.ศ. หรือไม่ (default as `false`)
 * @param noTime - ให้แสดงเวลาด้วยหรือไม่ (default as `false`)
 * @returns
 */
export const GetFormatVisitDate = (
  admitDate: string | null,
  encounterDate: string | null,
  isAd: boolean = false,
  noTime: boolean = false
) => {
  // console.log("GetFormatVisitDate Data:", { admitDate, encounterDate, isAd, noTime });

  if (!admitDate && !encounterDate) return null;

  //* Note admit_date default เป็น ค.ศ. ส่วน encounter_created เป็น พ.ศ.
  if (admitDate) {
    return ParseDate(admitDate, true, isAd, noTime);
  }
  if (encounterDate) {
    return ParseDate(encounterDate, false, isAd, noTime);
  }

  return null;
};

/**
 * Format วันเกิดของผู้ป่วย
 * @param birthDate
 * @param isAd - แสดงเป็น ค.ศ. หรือไม่ (default as `false`)
 * @returns
 */
export const GetFormatBirthDate = (birthDate: string | null, isAd: boolean = false) => {
  if (!birthDate) return null;

  const [day, month, year] = birthDate.split("/");

  if (isAd) {
    return `${day}/${month}/${parseInt(year) - 543}`;
  }

  return birthDate;
};

/**========================================================================
 **                              COMMON
 *========================================================================**/

/**
 * เรียกข้อมูลเบื้องต้นสำหรับแสดงในเอกสาร
 * @param props.controller - controller from CardPrintList (`props.controller`)
 * @param props.patientInfo - props from CardPrintList (`props.patientInfo`)
 * @param props.encounterId - Encounter ID
 * @param props.isEnableHeader - เปิดใช้งาน Header หรือไม่ (อยู่ที่ base.json ของ Site นั้น ๆ)
 * @returns
 */
export const GetPreparedData = async (props: {
  controller: ControllerType;
  patientInfo: any;
  encounterId: string | number;
  isEnableHeader: boolean;
}) => {
  // Date Time Data
  const currentDate = moment().format("YYYY-MM-DD, HH:mm"); // 2024-02-29, 14:30
  const christYear = moment(currentDate).format("YYYY");
  const buddhistYear = (parseInt(christYear) + 543).toString();
  const formattedDate = moment(currentDate).format("DD/MM/YYYY".replace("YYYY", buddhistYear));
  const printedDateFormTh = moment(currentDate)
    .locale("th")
    .format("DD/MM/YYYY, HH:mm".replace("YYYY", buddhistYear)); // 29/02/2567, 14:30
  const printedDateFormEn = moment(currentDate).format("DD/MM/YYYY, HH:mm"); // 29/02/2024, 14:30
  const printedDateUsageTh = printedDateFormTh.split(", "); // ["29/02/2567", "14:30"]
  const printedDateUsageEn = printedDateFormEn.split(", "); // ["29/02/2024", "14:30"]

  return {
    prepCreatedDateTh: GetSlideDate(printedDateFormTh),
    prepCreatedDateEn: GetSlideDate(printedDateFormEn, "en"),
    prepSignedDateTh: printedDateUsageTh,
    prepSignedDateEn: printedDateUsageEn,
    prepPatientName: await GetPatientNameInForm(props.patientInfo),
    prepPatientAge: CalculateAge(beToAd(props.patientInfo?.birthdate), beToAd(formattedDate)),
    prepPatientBirthDateEn: beStringToAdString(props.patientInfo?.birthdate),
    prepPatientAddress: GetFormatAddress(props.patientInfo),
    prepDoctorName: await GetDoctorNameInForm(props.controller, props.encounterId),
    haveHeader: props.isEnableHeader,
  };
};

/**
 * เรียก Master Option ที่เกี่ยวข้องในการแสดงในเอกสาร
 * @param propsMaster - props from CardPrintList (`props.masterOptions`)
 * @returns
 */
export const GetRegData = async (propsMaster: any) => {
  const masterOptions = propsMaster ?? {};

  // console.log("[Print List Common] Master Data: ", masterOptions);

  return {
    prenameTh: GetArrayorEmpty(masterOptions.prenameTh), // คำนำชื่อไทย
    prenameEn: GetArrayorEmpty(masterOptions.prenameEn), // คำนำชื่อ Eng
    bloodType: GetArrayorEmpty(masterOptions.bloodType), // หมู่เลือด
    nationality: GetArrayorEmpty(masterOptions.nationality), // สัญชาติ
    race: GetArrayorEmpty(masterOptions.race), // เชื้อชาติ
    religion: GetArrayorEmpty(masterOptions.religion), // ศาสนา
    belief: GetArrayorEmpty(masterOptions.belief), // ความเชื่อ
    marriage: MARRIAGE_STATUS, // สถานภาพสมรส
    education: GetArrayorEmpty(masterOptions.education), // ระดับการศึกษา
    birth_province: GetArrayorEmpty(masterOptions.province), // จังหวัดที่เกิด
    birth_country: GetArrayorEmpty(masterOptions.country), // ประเทศที่เกิด
  };
};

/**
 * แสดงชื่อผู้ป่วยในเอกสาร
 * @param propsPatientInfo - props from CardPrintList (`props.patientInfo`)
 * @returns
 */
export const GetPatientNameInForm = async (propsPatientInfo: any) => {
  const patientInfo = propsPatientInfo ?? {};

  const firstName = GetValueOrDefault(patientInfo.first_name, "");
  const lastName = GetValueOrDefault(patientInfo.last_name, "");
  const firstNameEn = GetValueOrDefault(patientInfo.first_name_en, "");
  const lastNameEn = GetValueOrDefault(patientInfo.last_name_en, "");
  const firstNameTh = GetValueOrDefault(patientInfo.first_name_th, "");
  const lastNameTh = GetValueOrDefault(patientInfo.last_name_th, "");

  let fullNameEn = GetValueOrDefault(patientInfo.full_name_en);
  let fullNameTh = GetValueOrDefault(patientInfo.full_name_th);

  // กรณีมีวงเล็บหลังชื่อ เช่น "นายทดสอบ รอบใหม่ (2)" ให้เอาวงเล็บออก
  fullNameEn = fullNameEn.replace(/\s?\(\d+\)/, "").trim();
  fullNameTh = fullNameTh.replace(/\s?\(\d+\)/, "").trim();

  const patientNameWithoutPrefix = firstName && lastName ? `${firstName} ${lastName}`.trim() : "";
  const patientNameWithoutPrefixEn =
    firstNameEn && lastNameEn ? `${firstNameEn} ${lastNameEn}`.trim() : "";
  const patientNameWithoutPrefixTh =
    firstNameTh && lastNameTh ? `${firstNameTh} ${lastNameTh}`.trim() : "";

  // ชื่ออังกฤษ (ถ้าไม่มีให้ fallback เป็นภาษาไทย)
  const patientNameInFormEn =
    patientNameWithoutPrefixEn || patientNameWithoutPrefixTh || patientNameWithoutPrefix;
  // ชื่อภาษาไทย (ถ้าไม่มีให้ fallback เป็นค่าเริ่มต้น)
  const patientNameInFormTh = patientNameWithoutPrefixTh || patientNameWithoutPrefix;

  return {
    withPrefixTh: fullNameTh || fullNameEn,
    withPrefixEn: fullNameEn || fullNameTh,
    withoutPrefixTh: patientNameInFormTh,
    withoutPrefixEn: patientNameInFormEn,
  };
};

/**
 * แสดงชื่อแพทย์ในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param encounterId - Encounter Id
 */
export const GetDoctorNameInForm = async (
  controller: ControllerType,
  encounterId: string | number
) => {
  const doctor = await GetDoctorInfoFromEncounter(controller, encounterId);
  const preNameEn = await GetPreName(controller, "EN");
  const getPrename = GetPreNameFromId(preNameEn?.items, doctor?.pre_name_en);

  const doctorFirstNameEn = GetValueOrDefault(doctor?.first_name_en, "");
  const doctorLastNameEn = GetValueOrDefault(doctor?.last_name_en, "");
  const doctorFirstNameTh = GetValueOrDefault(doctor?.first_name, "");
  const doctorLastNameTh = GetValueOrDefault(doctor?.last_name, "");

  const doctorFullNameTh = GetValueOrDefault(doctor?.full_name, " ");
  const doctorFullNameEn = getPrename
    ? `${getPrename}${doctorFirstNameEn} ${doctorLastNameEn}`.trim()
    : `${doctorFirstNameEn} ${doctorLastNameEn}`.trim();

  const doctorNameWithoutPrefixEn =
    doctorFirstNameEn && doctorLastNameEn ? `${doctorFirstNameEn} ${doctorLastNameEn}`.trim() : "";
  const doctorNameWithoutPrefixTh =
    doctorFirstNameTh && doctorLastNameTh ? `${doctorFirstNameTh} ${doctorLastNameTh}`.trim() : "";

  // ชื่ออังกฤษ (ถ้าไม่มีให้ fallback เป็นภาษาไทย)
  const doctorNameInFormEn = doctorNameWithoutPrefixEn || doctorFullNameEn || doctorFullNameTh;
  // ชื่อภาษาไทย (ถ้าไม่มีให้ fallback เป็นค่าเริ่มต้น)
  const doctorNameInFormTh = doctorNameWithoutPrefixTh || doctorFullNameTh || doctorFullNameEn;

  return {
    withPrefixTh: doctorFullNameTh || doctorFullNameEn,
    withPrefixEn: doctorFullNameEn || doctorFullNameTh,
    withoutPrefixTh: doctorNameInFormTh,
    withoutPrefixEn: doctorNameInFormEn,
  };
};

/**
 * แสดงชื่อแพทย์วิสัญญี (OperatingOrder) ในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param anesId - Anesthesiologist id
 * @param language
 * @returns
 */
export const GetAnesthesiologistNameInForm = async (
  controller: ControllerType,
  anesId: string | number,
  language: "th" | "en"
) => {
  if (!anesId) {
    console.warn(`[Print List Common] GetAnesthesiologist Anes ID not found!`);
    return null;
  }

  const anes = await GetDoctorDetail(controller, anesId);

  if (!anes) return null;

  const anesFirstNameEn = GetValueOrDefault(anes?.first_name_en, "");
  const anesLastNameEn = GetValueOrDefault(anes?.last_name_en, "");
  const anesFirstNameTh = GetValueOrDefault(anes?.first_name, "");
  const anesLastNameTh = GetValueOrDefault(anes?.last_name, "");

  const anesFullName = GetValueOrDefault(anes?.full_name);

  const anesNameWithoutPrefixEn =
    anesFirstNameEn && anesLastNameEn ? `${anesFirstNameEn} ${anesLastNameEn}`.trim() : "";
  const anesNameWithoutPrefixTh =
    anesFirstNameTh && anesLastNameTh ? `${anesFirstNameTh} ${anesLastNameTh}`.trim() : "";

  if (language === "en") {
    return anesNameWithoutPrefixEn || anesNameWithoutPrefixTh || anesFullName;
  }

  return anesFullName;
};

/**
 * แสดงชื่อโรงพยาบาลในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param language - Language Hospital Name ("th", "en")
 * @returns
 */
export const GetHospitalNameInForm = async (controller: ControllerType, language: "th" | "en") => {
  const langMapping = {
    th: "core_HOSPITAL_NAME",
    en: "core_HOSPITAL_NAME_EN",
  } as Record<"th" | "en", string>;

  const getHospitalName = await GetHospitalName(
    controller,
    language === "th" ? LANGUAGE_USAGE.TH : LANGUAGE_USAGE.EN
  );

  if (!getHospitalName) return null;

  return getHospitalName?.result?.[langMapping[language]] || " ";
};

/**
 * เรียกข้อมูล Operating Order สำหรับแสดงในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param emrId - EMR ID
 * @param language
 * @returns
 */
export const GetOperatingOrderInForm = async (
  controller: ControllerType,
  emrId: string | number | undefined,
  language: "th" | "en"
) => {
  if (!emrId) {
    console.log(`[Print List Common] GetOperatingOrder has no EMR ID!`);
    return null;
  }

  const resultOperatingOrder = await GetOperatingOrderDetail(controller, emrId);

  // console.log("[Print List Common] Original Resp: ", resultOperatingOrder);

  if (!resultOperatingOrder) return null;

  const operatingData = resultOperatingOrder ?? {};
  const anesTeams = operatingData.anesthesia_teams || [];

  // Get Anesthesiologist
  const anesId = anesTeams[0]?.anesthesiologist || null;
  const anesName = (await GetAnesthesiologistNameInForm(controller, anesId, language)) || " ";

  // Get Operating and ICD
  const operatingSummary = GetValueOrDefault(operatingData?.pre_operation_summary, "");
  const diagnosisSummary = GetValueOrDefault(operatingData?.preoperative_diagnosis_summary, "");
  const operationRegExp = operatingSummary.match(/1\. ([^\n]+)/);
  const operationRegExpUse = operationRegExp ? operationRegExp[1] : "";
  const icd9RegExp = diagnosisSummary.match(/2\. ([^\n]+)/);
  const icd9RegExpUse = icd9RegExp ? icd9RegExp[1] : "";
  const icd10RegExp = diagnosisSummary.match(/1\. ([^\n]+)/);
  const icd10RegExpUse = icd10RegExp ? icd10RegExp[1] : "";
  const operationOneLine =
    [icd10RegExpUse, icd9RegExpUse, operationRegExpUse].filter(Boolean).join(", ").trim() || " ";
  const primaryDoctor = GetValueOrDefault(operatingData?.primary_doctor_name, "");
  const operatingRoom = GetValueOrDefault(operatingData?.operating_room_no);

  const operatingDetailData = {
    operation_summary: operatingData.pre_operation_summary,
    diagnosis_summary: operatingData.preoperative_diagnosis_summary,
    operation_regexp: operationRegExpUse,
    icd9_regexp: icd9RegExpUse,
    icd10_regexp: icd10RegExpUse,
    operation_one_line: operationOneLine,
    anes_name: anesName,
    primary_doctor_name: primaryDoctor,
    operating_room_no: operatingRoom,
    formattedOperation: "",
  };

  // Get Treatment Data
  const treatmentCodeReExp = operatingSummary.match(/\[([^\]]+)\]/);
  const treatmentCode = treatmentCodeReExp ? treatmentCodeReExp[1] : null;

  if (treatmentCode) {
    const getTreatmentData = await GetTreatmentDetail(controller, treatmentCode);

    const treatmentItem = getTreatmentData?.[0]?.items?.[0] || {};

    const treatmentName =
      language === "en"
        ? treatmentItem?.name_en.trim() || treatmentItem?.name.trim()
        : treatmentItem?.name.trim();

    const formattedTreatment = treatmentName
      ? `[${treatmentCode}] ${treatmentName}`
      : treatmentItem?.name_code;

    operatingDetailData.formattedOperation = formattedTreatment;
  } else {
    operatingDetailData.formattedOperation = operatingDetailData.operation_regexp || " ";
  }

  // console.log(`[Print List Common] Operating Detail Data: `, operatingDetailData);

  return operatingDetailData;
};

/**
 * เรียกข้อมูล Diagnosis มาแสดงในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param emrId - EMR ID
 * @returns
 */
export const GetDiagnosisTreatmentInForm = async (
  controller: ControllerType,
  emrId: string | number | undefined
) => {
  if (!emrId) {
    console.log(`[Print List Common] GetDiagnosisTreatment has no EMR ID!`);
    return null;
  }

  const resultDiagnosisTreatment = await GetDiagnosisMedRecordDetail(controller, emrId);

  if (!resultDiagnosisTreatment) return null;

  const diagnosisTreatmentData = resultDiagnosisTreatment;
  const principalDiagnosis = diagnosisTreatmentData?.principal_diagnosis?.[0] ?? {};
  const icd10Code = GetValueOrDefault(principalDiagnosis?.icd_code);
  const icd10Term = GetValueOrDefault(principalDiagnosis?.icd_term);
  const icd10MedTerm = GetValueOrDefault(principalDiagnosis?.icd10_med_term);

  return {
    principal_diagnosis: principalDiagnosis,
    icd10_code: icd10Code,
    icd10_term: icd10Term,
    icd10_med_term: icd10MedTerm,
  };
};

/**
 * เรียกข้อมูล Allergy (ADR) มาแสดงในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param propsPatientInfo - props from CardPrintList (`props.patientInfo`)
 * @returns
 */
export const GetAllergyInForm = async (controller: ControllerType, propsPatientInfo: any) => {
  const patientInfo = propsPatientInfo ?? {};

  if (!patientInfo) {
    console.warn(`[Print List Common] GetAllergy has no patient!`);
    return null;
  }

  const resultPatientAllergy = await GetPatientAllergy(controller, patientInfo.id);

  if (!resultPatientAllergy) return null;

  const patientAllergyData = resultPatientAllergy;

  // Format Allergy
  const formattedPatientAllergy =
    patientAllergyData?.items
      ?.filter((item: any) => item.status !== "NO_REACTION")
      .map((item: any) => {
        if (item.type_name_name === "DRUG") {
          item.name = item.name ? `${item.name} (${item.adversary_type?.toLowerCase()})` : item.note;
        }
        return item.name;
      })
      .join(", ") || " ";

  // console.log("[Print List Common] Allergy Data: ", {ori: patientAllergyData, format: formattedPatientAllergy});

  return {
    allergyData: patientAllergyData,
    formattedAllergyData: formattedPatientAllergy,
  };
};

/**
 * เรียกข้อมูล ใบวิชาชีพของแพทย์ มาแสดงในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param propsPatientInfo - props from CardPrintList (`props.patientInfo`)
 * @param propsMaster - props from CardPrintList (`props.masterOptions`)
 * @returns
 */
export const GetDoctorCertNumberInForm = async (
  controller: ControllerType,
  propsPatientInfo: any,
  propsMaster: any
) => {
  const patientInfo = propsPatientInfo ?? {};
  const masterOptions = propsMaster ?? {};

  if (!patientInfo) {
    console.warn(`[Print List Common] GetDoctorCertNumber has no doctor!`);
    return null;
  }

  const getDoctorId = masterOptions.doctor
    .filter((item: any) => {
      return new RegExp(patientInfo.doctor_name).test(item.text);
    })
    .map((item: any) => item.value);

  const resultDoctorCertNumber = await GetDoctorDetail(controller, getDoctorId[0]);

  if (!resultDoctorCertNumber) return null;

  // console.log("[Print List Common] Doctor Cert Number: ", resultDoctorCertNumber[0]?.certificate_no);

  return resultDoctorCertNumber?.certificate_no || " ";
};

/**
 * เรียกข้อมูล สัญชาติ มาแสดงในเอกสาร
 * @param propsPatientInfo - props from CardPrintList (`props.patientInfo`)
 * @param propsMaster - props from CardPrintList (`props.masterOptions`)
 * @returns
 */
export const GetNationalityInForm = async (propsPatientInfo: any, propsMaster: any) => {
  const patientInfo = propsPatientInfo ?? {};
  const masterOptions = propsMaster ?? {};

  if (!patientInfo) {
    console.log(`[Print List Common] GetNationality has no patient!`);
    return null;
  }

  const nationalityText = masterOptions.nationality
    .filter((item: any) => {
      return item.value === patientInfo?.nationality;
    })
    .map((item: any) => item.text);

  if (!nationalityText) return null;

  const getNationality = nationalityText[0].split(" ");

  return {
    nationalityTh: getNationality[getNationality.length - 1] || " ",
    nationalityEn: getNationality[0] || " ",
  };
};

/**
 * เรียกข้อมูล Admit Order มาแสดงในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param encounterId - Encounter ID
 * @returns
 */
export const GetAdmitOrderInForm = async (
  controller: ControllerType,
  encounterId: string | number | undefined
) => {
  if (!encounterId) {
    console.warn(`[Print List Common] GetAdmitOrder has no encounter!`);
    return null;
  }

  const resultAdmitOrder = await GetAdmitOrder(controller, encounterId);

  if (!resultAdmitOrder) return null;

  return resultAdmitOrder.items[0] || {};
};

/**
 * เรียกข้อมูล Med Cert (Cudent) มาแสดงในใบรับรองแพทย์
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param encounterNumber - Encounter number
 * @param patientId - Patient ID
 * @returns
 */
export const GetCudentMedCertInForm = async (
  controller: ControllerType,
  encounterNumber: string | number,
  patientId: string | number
) => {
  if (!encounterNumber && !patientId) {
    console.warn(`[Print List Common] GetCudentMedCert has no encounter number or patient!`);
    return null;
  }

  // Func
  const sortByCreated = (items: any[]) =>
    items?.sort((a, b) => new Date(a?.created_utc).getTime() - new Date(b?.created_utc).getTime());
  const getLastItem = (items: any[]) => (items.length > 0 ? items[items.length - 1] : null);

  const getMedCert = async (formCode: string) => {
    const resp = await GetCudentMedCertInfo(controller, {
      encounterNumber,
      patientId,
      formCode,
    });

    if (!resp) {
      console.warn(`[Print List Common] Error to get ${formCode} Med Cert!`);
      return [];
    }

    return resp.items || [];
  };

  const getLatestMedCerts = async () => {
    const [cudentLatest, dentistLatest] = await Promise.all([
      GetCudentMedCertInfo(controller, {
        patientId,
        formCode: "MedicalCertificateCUDent",
      }),
      GetCudentMedCertInfo(controller, {
        patientId,
        formCode: "CardDentistCertificate",
      }),
    ]);

    const cudentItems = cudentLatest ? cudentLatest?.items : [];
    const dentistItems = dentistLatest ? dentistLatest?.items : [];

    return [...cudentItems, ...dentistItems];
  };

  const [cudentMedCert, dentistMedCert] = await Promise.all([
    getMedCert("MedicalCertificateCUDent"),
    getMedCert("CardDentistCertificate"),
  ]);

  const combinedMedCerts = sortByCreated([...cudentMedCert, ...dentistMedCert]);

  if (!getLastItem(combinedMedCerts)) {
    console.warn(
      "[Print List Common] Med Cert not found from this encounter number! ",
      encounterNumber
    );

    // Get Latest Med Cert
    const latestMedCerts = await getLatestMedCerts();
    const sortedLatestMedCerts = sortByCreated(latestMedCerts);

    if (!getLastItem(sortedLatestMedCerts)) {
      console.warn("Print List: Med Cert not found!");
      return null;
    }

    return {
      code: getLastItem(sortedLatestMedCerts)?.id || "-",
      data: getLastItem(sortedLatestMedCerts) || {},
    };
  }

  return {
    code: getLastItem(combinedMedCerts)?.id || "-",
    data: getLastItem(combinedMedCerts) || {},
  };
};

/**
 * เรียกข้อมูล Bill Encounter Summary มาแสดงในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param patientId - Patient ID
 * @param encounterNumber - Encounter number
 * @returns
 */
export const GetBillEncounterSummaryInForm = async (
  controller: ControllerType,
  patientId: string | number,
  encounterNumber: string | number
) => {
  if (!patientId && !encounterNumber) {
    console.warn(`[Print List Common] GetBillEncounterSummary has no encounter number or patient!`);
    return null;
  }

  const result = await GetBillEncounterSummary(controller, patientId);

  if (result) {
    const resultList = result.items;
    return resultList?.filter((item: any) => item.number === encounterNumber)[0] || {};
  }

  return null;
};

/**
 * เรียกข้อมูล IPD Medical Bill มาแสดงในเอกสาร
 * @param controller - controller from CardPrintList (`props.controller`)
 * @param encounterId - Encounter ID
 * @returns
 */
export const GetIpdMedicalBillInForm = async (
  controller: ControllerType,
  encounterId: string | number | undefined,
  patientId: string | number | undefined
) => {
  if (!encounterId && !patientId) {
    console.warn(`[Print List Common] GetIpdMedicalBill has no encounter or patient!`);
    return null;
  }

  const ipdBillRes = await GetIpdMedBill(controller, encounterId);
  const invoiceItemsRes = await GetInvoiceItemList(controller, patientId);

  // if (result) return result;
  if (ipdBillRes) {
    const billedPrice = (invoiceItemsRes.items || []).reduce(
      (accumulator: any, item: any) => accumulator + Number.parseFloat(item.pay),
      0
    );

    return {
      ...ipdBillRes,
      total_billed_price: billedPrice,
    };
  }

  return null;
};
