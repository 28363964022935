import React, { useState, forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Modal, Grid, Segment, Button, Input } from "semantic-ui-react";

import axios from "axios";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ComboBox from "react-lib/apps/common/ComboBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import CardEncounterList from "react-lib/apps/common/CardEncounterList";
import PatientIdentification from "react-lib/apps/common/PatientIdentification";
import FrameChrome from "react-lib/apps/common/FrameChrome";
import SearchBox from "react-lib/apps/common/SearchBox";
import PureReactTable from "./PureReactTable";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Util
import { beToAd, beStringToAdString } from "react-lib/utils/dateUtils";
import { vcMessenger } from "react-lib/compat/vc-websocket";
import moment from "moment";
import _ from "react-lib/compat/lodashplus";
import { printURL } from "react-lib/utils/printURL";

// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

// API
import { GetEncounter, GetPatientDetailView } from "./CardPrintListApiService";

// Utils and Common
import {
  CalculateAge,
  GetDoctorNameInForm,
  GetFormatAddress,
  GetPatientNameInForm,
  GetRegData,
  GetSlideDate,
} from "./CardPrintListCommon";

// FormDataHelper
import {
  FALLBACK_PDF_PATHS,
  FORM_PDFDATA_MAPPING,
  CUSTOM_PDF_LIST,
  GetFormData,
  CreateMergedForm,
  CreateWithHeaderForm,
} from "./CardPrintListFormDataHelper";

// Const c.
const HOST = `${CONFIG.API_HOST}`;
const isEnableHeader = CONFIG.ENABLE_HEADER_PRINTLIST;
const COMPANY = CONFIG.COMPANY;

/*=============================================
 *                    MAIN
 *=============================================*/

/* Note About Inject Data in PDF Form
- FormConsentPreSedationTH : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentPreSedationEN : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentTreatmentProcedure : Inject Table Finger Print หลัง Paragraph "ทั้งนี้ข้าพเจ้า เข้าใจ......เป็นหลักฐาน"
- FormNewPatientRegistration : มีการ Inject Checkbox, Square Box และ Fix Position เช่น เลขบัตรประชาชน, เลขไปรษณีย์ใน Square Box
*/

const CardPrintList = forwardRef((props: any, ref) => {
  const intl = useIntl();
  const [taskTracking, setTaskTracking] = useState(null);
  const [openModEncounterList, setOpenModEncounterList] = useState(false);
  const [encounterId, setEncounterId] = useState(props.encounterId);
  const [encounterList, setEncounterList] = useState([]); //* as default options for SearchBox
  const [documentType, setDocumentType] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<string | null>(null); //* pdf
  const [message, setMessage] = useState<any>({}); //* msg from vcMessenger
  const [patientInfo, setPatientInfo] = useState(props.patientInfo); // for Search Encounter
  const [emrId, setEmrId] = useState(props.patientData?.EMR?.emr_id); // for Search Encounter
  const [encounterNumber, setEncounterNumber] = useState(props.encounterNumber); // for Search Encounter
  const [doctorName, setDoctorName] = useState(props.patientInfo?.doctor_name); // for Search Encounter
  const [isSearchDocumentLoading, setIsSearchDocumentLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  //* show error and success
  const [openModInfo, setOpenModInfo] = useState(false);
  const [modInfoTitle, setModInfoTitle] = useState("");
  const [modInfoTitleColor, setModInfoTitleColor] = useState("");
  const [modInfoText, setModInfoText] = useState("");

  //* Mod Show task progress
  const [openModInfoTask, setOpenModInfoTask] = useState(false);
  const [modInfoTaskText, setModInfoTaskText] = useState("");

  //* Don't know what this is
  const [getPrinted, setGetPrinted] = useState(false);

  //* Cant find use case yet
  const [openModConfirm, setOpenModConfirm] = useState(false);
  const [modConfirmTitle, setModConfirmTitle] = useState("แจ้งเตือน!!!");
  const [reasonChoices, setReasonChoices] = useState([]);
  const [modConfirmError, setModConfirmError] = useState(null);

  const isMounted = useRef(false);
  const sbxEncounterRef = useRef();
  const reasonRef = useRef();

  useEffect(() => {
    isMounted.current = true;
    getDocumentType();
    vcMessenger.onMessage((msg: any) => {
      try {
        setMessage(msg);
      } catch (e) {}
    });
    getReason();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (message.event === "DPI_DOCTOR_ORDER_PRINT") {
      if (message.task_id === taskTracking) {
        handleTaskTrackingStatus({ data: message });
      }
    }
  }, [message]);

  useEffect(() => {
    if (!props.encounterId) {
      setEncounterId(props.patientInfo?.encounter_id);
    }
  }, [props.encounterId]);

  useEffect(() => {
    if (props.encounterNumber) {
      handleSearchEncounter({ searchText: props.encounterNumber });
    }
  }, [props.encounterNumber]);

  useEffect(() => {
    if (taskTracking) {
      var task = setInterval(() => {
        getTaskTracking();
      }, 15000);
    }
    return () => clearInterval(task);
  }, [taskTracking]);

  useEffect(() => {
    GetRegData(props.masterOptions);
  }, [props.masterOptions]);

  const clear = () => {
    setFile(null);
    setSelectedRow(null);
    getDocumentType();
    setTaskTracking(null);
    setGetPrinted(false);
    // frmError.clear()
    // default Data
    setEncounterId(props.encounterId);
    setPatientInfo(props.patientInfo);
    setEmrId(null);
    setEncounterNumber(null);
    setDoctorName(null);
  };

  const getReason = async () => {
    const [data, error] = await props.controller.getClinicalTerm({
      type: "REASON_PATIENT_CARD",
    });
    if (isMounted.current) {
      if (data) {
        setReasonChoices(data);
      }
    }
  };

  const getPdfBase64 = async (url: string): Promise<string> => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });

      return Buffer.from(response.data, "binary").toString("base64");
    } catch (error) {
      console.error(`Failed to fetch PDF:`, error);

      return "";
    }
  };

  const getPdfBlob = async (url: string): Promise<Blob | null> => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });

      return new Blob([response.data], { type: response.headers["content-type"] });
    } catch (error) {
      console.error(`Failed to fetch PDF:`, error);

      return null;
    }
  };

  const handleTaskTrackingStatus = ({ data }: any = {}) => {
    // console.log("Print List Data: ", data);
    if (data) {
      if (data.status === TASK_TRACKING_STATUS.COMPLETED && !getPrinted) {
        setTaskTracking(null);
        getPrintedDocument();
        setGetPrinted(true);
        setOpenModInfoTask(false);
      } else if (data.status === TASK_TRACKING_STATUS.FAILED) {
        setTaskTracking({ taskId: data.task_id } as any);
        setModInfoTaskText(data.task_id);
        setModInfoTitle("ผิดพลาด");
        setModInfoTitleColor("red");
      } else if (data.status === TASK_TRACKING_STATUS.CANCELED) {
        setTaskTracking(null);
        setModInfoText(data.current_progress_description);
        setModInfoTitle("ยกเลิก");
        setModInfoTitleColor("red");
        setOpenModInfoTask(false);
        setOpenModInfo(true);
      } else if (data.status === TASK_TRACKING_STATUS.IN_PROGRESS) {
        setModInfoTaskText(
          (
            <div style={{ textAlign: "left", padding: 0 }}>
              <div>{intl.formatMessage({ id: "กรุณารอสักครู่" })}</div>
              <div>
                Status:{" "}
                {`${data.status} ${data.current_progress ? data.current_progress : data.progress}%`}
              </div>
              For IT: task_id = {data.task_id}
            </div>
          ) as any
        );
      } else if (!data.hasOwnProperty("status")) {
        setTaskTracking(null);
        handleGetDocumentResult({ data, preview: true });
      }
    }
  };

  const getPrintedDocument = ({ taskId }: any = {}) => {
    (reasonRef as any).current.clear();
    getTaskTracking({ taskId } as any);
  };

  const getTaskTracking = async ({ action, taskId }: any = {}) => {
    const [data, error] = await props.controller.getTaskTrackingResult({
      taskId: taskId ? taskId : taskTracking,
      action,
    });
    if (isMounted.current) {
      handleTaskTrackingStatus({ data });
    }
  };

  const getDocumentType = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      // name: intl.formatMessage({id: "ใบลงทะเบียนผู้ป่วยใหม่ (New Patient Registration Form)"}),
      printable: true,
      division: "current",
      offset: 0,
      limit: 100,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsLoading(false);
  };

  const handleSearchEncounter = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearch({
      // patientId: props.patientId, // #65599 show all encounter
      patientHn: props.patientInfo?.hn,
      search: searchText,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterList(data.items);
      }
    }
    return [data, error];
  };

  // Search Document
  const handleSearchDocumentType = async ({ target }: any) => {
    const searchText = target.value;
    setSearchInput(searchText);
    const minCharacters = 3;

    if (searchText.length === 0) {
      getDocumentType();
      return;
    }
    if (searchText.length >= minCharacters) {
      debounceSearchDocument(searchText);
    }
  };

  // Debounce Search Document
  const debounceSearchDocument = _.debounce(async (inputValue: any) => {
    setIsSearchDocumentLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      name: inputValue,
      printable: true,
      division: "current",
      offset: 0,
      limit: 100,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsSearchDocumentLoading(false);
  }, 500);

  const handleSelectOption = async ({ item }: any = {}) => {
    getEncounterSearchDetail({ encounterId: item.id });
  };

  const getEncounterSearchDetail = async ({ encounterId }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearchDetail({
      encounterId,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterId(data.id);
        setEncounterNumber(data.number);
        setDoctorName(data.doctor_name);
      }

      // get Encounter Detail
      let encounterData = await GetEncounter(props.controller, encounterId);

      // get Patient Detail
      if (encounterData) {
        setEmrId(encounterData.medical_record);

        let patientDetailData = await GetPatientDetailView(props.controller, encounterData.patient);

        if (patientDetailData) {
          setPatientInfo(patientDetailData[0]);
        }
      }
    }
  };

  const handleSelectDocType = (originalRow: any) => {
    setSelectedRow(originalRow);
    // console.log("Print List Original Row: ", originalRow);
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow, preview: true } as any);
    } else {
      getPrintPDFMake({ originalRow, print: false } as any);
    }
  };

  const handlePrintAPIFail = ({ error, preview, confirm }: any = {}) => {
    if (preview) {
      clear();
    }
    setFile(null);
    if (confirm) {
      if (error) {
        setModConfirmTitle(error.message);
      }
      setOpenModConfirm(true);
    } else {
      setModInfoTitle("ผิดพลาด");
      setModInfoTitleColor("red");
      if (error) {
        if (error.message) {
          setModInfoText(error.message);
        } else {
          setModInfoText(error);
        }
      }
      setOpenModInfo(true);
    }
  };

  const getPrintAPI = async ({ originalRow, preview, confirm }: any = {}) => {
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    const [data, error, network] = await props.controller.getPrintAPI({
      printAPI: HOST + originalRow.print_api,
      patientId: props.patientId ? props.patientId : props.patientInfo?.id, // #65578 and #65580 patientId is null
      orderId: props.operatingOrderId,
      encounterId: encounterId ? encounterId : props.patientInfo?.encounter_id, // #67954 encounter id on reg menu
      jasperModule: originalRow.jasper_module,
      jasperPath: originalRow.jasper_path,
      pdf: preview,
      confirm,
      reason: reasonRef.current ? (reasonRef as any).current.getValue() : "",
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        handleGetDocumentResult({ data, preview });
      } else if (error && ![500, 503].includes(network.response.status)) {
        handlePrintAPIFail({ error, preview, confirm });
      }
    }
  };

  const getPrintPDFMake = async ({ originalRow, print }: any = {}) => {
    // console.log("Print List PrintPDFMake (Func): ", originalRow);
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    let docPdfDef: any = { content: [] };
    let formGen: any = null;

    // Print/Create Date (Global)
    const currentDate = moment().format("YYYY-MM-DD, HH:mm"); // 2024-02-29, 14:30
    const christYear = moment(currentDate).format("YYYY");
    const buddhistYear = (parseInt(christYear) + 543).toString();
    const formattedDate = moment(currentDate).format("DD/MM/YYYY".replace("YYYY", buddhistYear));
    const printedDateFormTh = moment(currentDate)
      .locale("th")
      .format("DD/MM/YYYY, HH:mm".replace("YYYY", buddhistYear)); // 29/02/2567, 14:30
    const printedDateFormEn = moment(currentDate).format("DD/MM/YYYY, HH:mm"); // 29/02/2024, 14:30
    const printedDateUsageTh = printedDateFormTh.split(", "); // ["29/02/2567", "14:30"]
    const printedDateUsageEn = printedDateFormEn.split(", "); // ["29/02/2024", "14:30"]

    // Patient Info Format for Forms
    const formattedPatientName = await GetPatientNameInForm(props.patientInfo);
    const patientBirthdateFormEN = beStringToAdString(props.patientInfo?.birthdate);

    // Doctor Name Format for Forms
    const formattedDoctorName = await GetDoctorNameInForm(props.controller, encounterId);

    // formattedAddress
    const formattedAddress = GetFormatAddress(props);

    // getSliceDate
    let createdFormDate = GetSlideDate(printedDateFormTh);
    let createdFormDateEn = GetSlideDate(printedDateFormEn, "en");

    // patientInfo Variable
    let patientEmrId = props.patientData?.EMR?.emr_id;
    let patientDoctorName = props.patientInfo?.doctor_name;
    let patientEncounterNumber = props.patientInfo?.encounter_no;

    // Patient Age (FormattedDate - BirthDate)
    let patientAge = CalculateAge(beToAd(props.patientInfo?.birthdate), beToAd(formattedDate));

    // Data Selected
    if (encounterId) {
      props.encounterId = encounterId;
      props.encounterNumber = encounterNumber;
      patientEncounterNumber = encounterNumber;
      patientEmrId = emrId;
    }
    if (patientInfo) {
      props.patientInfo = patientInfo;
      patientDoctorName = doctorName;
    }

    const preparedData = {
      prepCreatedDateTh: createdFormDate,
      prepCreatedDateEn: createdFormDateEn,
      prepSignedDateTh: printedDateUsageTh,
      prepSignedDateEn: printedDateUsageEn,
      prepPatientName: formattedPatientName,
      prepPatientAge: patientAge,
      prepPatientBirthDateEn: patientBirthdateFormEN,
      prepPatientAddress: formattedAddress,
      prepDoctorName: formattedDoctorName,
      haveHeader: isEnableHeader,
      printer: props.printer || "-",
    };

    const formData = await GetFormData(originalRow?.code, {
      props,
      preparedData,
      emrId: emrId,
      encounterId: encounterId,
    });

    // console.log("Yeti Form Data: ", formData);

    if (formData) {
      if (CUSTOM_PDF_LIST.includes(originalRow?.code)) {
        const mergedPdf = await CreateMergedForm(
          FORM_PDFDATA_MAPPING[originalRow?.code],
          formData.data
        );
        docPdfDef = mergedPdf;
      } else {
        formGen = FORM_PDFDATA_MAPPING[originalRow?.code];
      }
    } else {
      const pathFile = originalRow.jasper_path || FALLBACK_PDF_PATHS[originalRow?.code];

      if (pathFile) {
        docPdfDef = { pathFile };
      } else {
        clear();
        return;
      }
    }

    if (formGen && formData) {
      docPdfDef = await CreateWithHeaderForm(
        formGen,
        formData,
        formData?.data?.items?.haveHeader,
        COMPANY,
        {
          createdFormDate: formData?.language === "th" ? printedDateFormTh : printedDateFormEn,
          formName: formData?.formName,
          patientInfo: formData?.data?.items,
          ...(formData.data?.items?.haveFooter && { footer: formData.data.items.haveFooter }),
        }
      );
    }

    // console.log("Print List PDF Data: ", docPdfDef);

    if (isMounted.current) {
      setIsLoading(false);
      if (docPdfDef && print === false) {
        handleGetPdfMakeResult(docPdfDef);
      } else if (docPdfDef && docPdfDef.blobRaw) {
        const blob = docPdfDef.blobRaw;

        if (blob) {
          printURL(URL.createObjectURL(blob));
          setOpenModInfoTask(false);
          setModInfoText("พิมพ์รายการสำเร็จ");
          setModInfoTitle("สำเร็จ");
          setModInfoTitleColor("teal");
          setOpenModInfo(true);
          clear();
        }
      } else if (docPdfDef && print === true) {
        // (await getPdfMake(true)).createPdf(docPdfDef).print();
        const pdfMake = await getPdfMake(true);
        const pdf = pdfMake.createPdf(docPdfDef);
        const pdfBlobFromPdf = new Promise<Blob>((resolve) => {
          pdf.getBlob((blob: any) => {
            resolve(blob);
          });
        });

        const blob: Blob | null = await (docPdfDef.pathFile
          ? getPdfBlob(docPdfDef.pathFile)
          : pdfBlobFromPdf);

        if (blob) {
          printURL(URL.createObjectURL(blob));
          setOpenModInfoTask(false);
          setModInfoText("พิมพ์รายการสำเร็จ");
          setModInfoTitle("สำเร็จ");
          setModInfoTitleColor("teal");
          setOpenModInfo(true);
          clear();
        }
      } else {
        setFile("about:blank" as any);
        console.error("PDF Data doesn't Exist");
      }
    }
  };

  const handleGetPdfMakeResult = async (data: any) => {
    if (data) {
      setOpenModInfo(false);

      let pdfB64Data = "";

      if (data.pathFile) {
        pdfB64Data = await getPdfBase64(data.pathFile);
      } else if (data.base64String) {
        pdfB64Data = data.base64String;
      } else {
        const pdfDocGen = (await getPdfMake(true)).createPdf(data);
        pdfB64Data = await new Promise<string>((resolve, reject) => {
          pdfDocGen.getBase64((data) => resolve(data));
        });
      }
      if (pdfB64Data) {
        // console.log("Yeti Base64: ", pdfB64Data);
        setFile(`data:application/pdf;base64,${pdfB64Data}`);
      }
    } else {
      console.error("Cannot Open PDF File!!");
    }
  };

  const handleGetDocumentResult = ({ data, preview }: any = {}) => {
    if (preview && data.success && data.pdf_b64data.length > 0) {
      setOpenModInfoTask(false);
      return setFile(("data:application/pdf;base64," + data.pdf_b64data) as any);
    } else if (!preview && data.success) {
      setOpenModInfoTask(false);
      setModInfoText("พิมพ์รายการสำเร็จ");
      setModInfoTitle("สำเร็จ");
      setModInfoTitleColor("teal");
      setOpenModInfo(true);
      clear();
    } else if (data.warning) {
      setFile("about:blank" as any);
      alert(data.warning);
    } else if (data.task_id) {
      setModInfoTaskText(
        (
          <div style={{ textAlign: "left", padding: 0 }}>
            <div>{intl.formatMessage({ id: "กรุณารอสักครู่" })}</div>For IT: task_id ={" "}
            {data.task_id}
          </div>
        ) as any
      );
      setOpenModInfoTask(true);
      setGetPrinted(false);
      setTaskTracking(data.task_id);
    } else {
      setOpenModInfoTask(false);
      handlePrintAPIFail({ error: data.error, preview });
    }
  };

  const handleCancelTask = () => {
    getTaskTracking({ action: "CANCEL" });
  };

  const handleApproveConfirm = () => {
    setModConfirmError(null);
    if (!(reasonRef as any).current.getValue()) {
      return setModConfirmError("กรุณาระบุเหตุผล" as any);
    }
    setOpenModConfirm(false);
    getPrintAPI({ originalRow: selectedRow, preview: false, confirm: true });
  };

  // Print
  const handlePrintReport = (originalRow: any) => {
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow: selectedRow, preview: false, confirm: false });
    } else {
      getPrintPDFMake({ originalRow: selectedRow, print: true });
    }
  };

  // Print List Data
  // console.log("Print List Props: ", props);

  return (
    <CardLayout
      titleText="พิมพ์เอกสาร"
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.onClose}
      loading={isLoading}
    >
      <ModConfirm
        openModal ={openModConfirm}
        className={"fixNotMiddleModal"}
        onDeny={() => setOpenModConfirm(false)}
        titleName={modConfirmTitle}
        onApprove={handleApproveConfirm}
        content={
          <Form>
            <ErrorMessage error={modConfirmError} />
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  ระบุเหตุผล
                  <label style={{ color: "red" }}>*</label>
                </label>
                <ComboBox ref={reasonRef as any} options={reasonChoices} />
              </Form.Field>
            </Form.Group>
          </Form>
        }
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfoTask}
        alertText={modInfoTaskText}
        titleName="กำลังเตรียมเอกสาร"
        btnText="ยกเลิก"
        titleColor="teal"
        buttonColor="red"
        textAlign={"left"}
        onApprove={handleCancelTask}
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfo}
        titleColor={modInfoTitleColor}
        titleName={modInfoTitle}
        alertText={
          typeof modInfoText !== "string" ? <ErrorMessage error={modInfoText} /> : modInfoText
        }
        closeOnDimmerClick={true}
        onClose={() => setOpenModInfo(false)}
        onApprove={() => setOpenModInfo(false)}
      />
      <Modal
        open={openModEncounterList}
        closeOnDimmerClick={true}
        onClose={() => setOpenModEncounterList(false)}
        content={
          <CardEncounterList
            patientId={props.patientId}
            patientHn={props.patientInfo?.hn}
            controller={props.controller}
            onSelect={(selectedRow: any) => {
              (sbxEncounterRef as any).current.setText(selectedRow.number_date);
              (sbxEncounterRef as any).current.setId(selectedRow.id);
              handleSelectOption({ item: selectedRow });
              setOpenModEncounterList(false);
            }}
          />
        }
      />
      <PatientIdentification patientInfo={patientInfo} />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <label>Encounter Number :</label>
          </Form.Field>
          <Form.Field>
            <SearchBox
              ref={sbxEncounterRef}
              testid="sbxEncounter"
              linkIcon
              onIconClick={() => setOpenModEncounterList(true)}
              minCharacters={2}
              textField="number_date"
              onGetSearchOptions={handleSearchEncounter}
              onSelectOption={handleSelectOption}
              onClear={() => setEncounterId(props.encounterId)}
              defaultOptions={encounterList}
              defaultId={props.encounterId ? props.encounterId : props.patientInfo?.encounter_id}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Grid>
        <Grid.Column width={4}>
          <Input
            fluid
            icon="search"
            placeholder="ค้นหาชื่อเอกสาร"
            onChange={handleSearchDocumentType}
            loading={isSearchDocumentLoading}
          />
          <br />
          <PureReactTable
            style={styles.docTable}
            showPagination={false}
            testid="grdRequest"
            data={documentType}
            onSelect={handleSelectDocType}
            selectedRow={selectedRow}
            selectedClassName="blueSelectedRow"
            columns={[
              {
                Header: "ชื่อเอกสาร",
                accessor: "name",
              },
            ]}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment style={{ height: "500px" }}>
            <FrameChrome file={file} visible />
          </Segment>
        </Grid.Column>
      </Grid>
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              color="blue"
              content="พิมพ์"
              fluid
              // onClick={() => {
              //   getPrintAPI({
              //     originalRow: selectedRow,
              //     preview: false,
              //     confirm: false,
              //   });
              // }}
              onClick={() => {
                handlePrintReport(selectedRow);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

const TASK_TRACKING_STATUS = {
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  IN_PROGRESS: "IN_PROGRESS",
};

const styles = {
  docTable: {
    height: "500px",
  },
};

CardPrintList.propTypes = {
  toggleable: PropTypes.bool,
  closeable: PropTypes.bool,
  patientInfo: PropTypes.object,
  onClose: PropTypes.func,
  patientData: PropTypes.object,
  controller: PropTypes.object,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operatingOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  printer: PropTypes.string,
};

CardPrintList.defaultProps = {
  toggleable: false,
  closeable: true,
  patientInfo: {},
  onClose: () => {},
  patientData: {},
  controller: {},
  patientId: null,
  encounterId: null,
  encounterNumber: null,
  operatingOrderId: null,
  printer: null,
};

CardPrintList.displayName = "CardPrintList";
export default React.memo(CardPrintList);
