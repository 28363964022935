// Type
export type ControllerType = {
  getEncounter: (encounterId: string | number) => Promise<any>;
  getDoctorDetail: (params: { doctorId: string | number }) => Promise<any>;
  getOperatingOrderDetailByEmr: (params: { emrId: string | number }) => Promise<any>;
  getPatientDetailView: (params: { patientId: string | number }) => Promise<any>;
  getDiagnosisMedicalRecordDetail: (params: { emrId: string | number }) => Promise<any>;
  getTreatmentDetail: (params: { code: string | number }) => Promise<any>;
  getHospitalNameForPrintList: (params: { language: string }) => Promise<any>;
  getPatientAllergyForPrintList: (params: { patientId: string | number }) => Promise<any>;
  getPreNameForPrintList: (params: { language: string }) => Promise<any>;
  getAdmitOrderForPrintList: (params: { encounterId: string | number }) => Promise<any>;
  getCudentMedCertForPrintList: (params: {
    encounterNumber: string | number;
    patientId: string | number;
    formCode: string;
  }) => Promise<any>;
  getBilEncounterSummaryForPrintList: (params: { patientId: string | number }) => Promise<any>;
  getIpdMedicalBilForPrintList: (params: { encounterId: string | number }) => Promise<any>;
  getInvoiceItemList: (params: { patientId: string | number }) => Promise<any>;
};

/**========================================================================
 **                            GENERIC FUNC
 *========================================================================**/

const fetchData = async <T>(
  controller: ControllerType,
  method: keyof ControllerType,
  params?: any
) => {
  try {
    const [data, err] = await controller[method](params);

    if (err) {
      console.warn(`[Print List API Service] Error Calling ${method}`, err);
      return null;
    }

    return data;
  } catch (error) {
    console.error(`[Print List API Service] Unexpected Error in ${method}`, error);
    return null;
  }
};

/**========================================================================
 **                                APIS
 *========================================================================**/

export const GetEncounter = (
  controller: ControllerType,
  encounterId: string | number | undefined
) => fetchData(controller, "getEncounter", encounterId);

export const GetDoctorDetail = (
  controller: ControllerType,
  doctorId: string | number | undefined
) => fetchData(controller, "getDoctorDetail", { doctorId });

export const GetOperatingOrderDetail = (
  controller: ControllerType,
  emrId: string | number | undefined
) => fetchData(controller, "getOperatingOrderDetailByEmr", { emrId });

export const GetPatientDetailView = (
  controller: ControllerType,
  patientId: string | number | undefined
) => fetchData(controller, "getPatientDetailView", { patientId });

export const GetDiagnosisMedRecordDetail = (
  controller: ControllerType,
  emrId: string | number | undefined
) => fetchData(controller, "getDiagnosisMedicalRecordDetail", { emrId });

export const GetTreatmentDetail = (controller: ControllerType, code: string | number | undefined) =>
  fetchData(controller, "getTreatmentDetail", { code });

export const GetHospitalName = (controller: ControllerType, language: string) =>
  fetchData(controller, "getHospitalNameForPrintList", { language });

export const GetPatientAllergy = (
  controller: ControllerType,
  patientId: string | number | undefined
) => fetchData(controller, "getPatientAllergyForPrintList", { patientId });

export const GetPreName = (controller: ControllerType, language: string) =>
  fetchData(controller, "getPreNameForPrintList", { language });

export const GetAdmitOrder = (
  controller: ControllerType,
  encounterId: string | number | undefined
) => fetchData(controller, "getAdmitOrderForPrintList", { encounterId });

export const GetBillEncounterSummary = (
  controller: ControllerType,
  patientId: string | number | undefined
) => fetchData(controller, "getBilEncounterSummaryForPrintList", { patientId });

export const GetIpdMedBill = (
  controller: ControllerType,
  encounterId: string | number | undefined
) => fetchData(controller, "getIpdMedicalBilForPrintList", { encounterId });

export const GetInvoiceItemList = (
  controller: ControllerType,
  patientId: string | number | undefined
) => fetchData(controller, "getInvoiceItemList", { patientId });

export const GetCudentMedCertInfo = (
  controller: ControllerType,
  params: {
    encounterNumber?: string | number;
    patientId: string | number;
    formCode: string;
  }
) => fetchData(controller, "getCudentMedCertForPrintList", params);

/**========================================================================
 **                              MISC APIS
 *========================================================================**/

// * GetEncouter + GetDoctorDetail
export const GetDoctorInfoFromEncounter = async (
  controller: ControllerType,
  encounterId: string | number | undefined
) => {
  if (!encounterId) return null;

  const encounterData = await GetEncounter(controller, encounterId);

  if (!encounterData?.doctor) {
    console.warn(
      `[Print List API Service] GetDoctorInfoFromEncounter Has no doctor!`,
      encounterData
    );

    return null;
  }

  return await GetDoctorDetail(controller, encounterData.doctor);
};
