import React, { SyntheticEvent } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { useIntl } from "react-intl";

type ComboBoxProps = {
  id?: string;
  value: string | any[] | number;
  allowAdditions: boolean;
  onValueChange: (value: any) => void;
  multiple: any;
  onChange: (e: SyntheticEvent<HTMLElement, Event>, { value }: any) => void;

  search: boolean;
  selection: boolean;
  disabled: boolean;
  fluid: boolean;
  compact: boolean;
  options: any[];
  error: boolean;
  minCharacters: number;
  noResultsMessage: string;
  placeholder: string;
  onLabelClick: any;
  clearable: boolean;
  testid: string;
  loading: boolean;
};

const ComboBox = React.forwardRef((props: ComboBoxProps, ref) => {
  const [cboValue, setCboValue] = React.useState("");
  const [localOptions, setLocalOptions] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      let value = formatValueToString();
      return value;
    },
    clear: () => {
      setCboValue("");
    },
  }));

  React.useEffect(() => {
    setLocalOptions(props.options);
  }, [props.options]);

  React.useEffect(() => {
    formatAndSetCboValue(props.value);
  }, [props.value]);

  const handleChange = (e: any, value: any) => {
    if (props.allowAdditions === false || props.multiple === false) {
      props.onValueChange(value);
      return setCboValue(value);
    }
    let optionsValue = props.options.map((item: any) => item.value);
    let newOptions = [...props.options];
    for (let items of value) {
      if (!optionsValue.includes(items)) {
        newOptions.push({
          key: items,
          value: items,
          text: items,
        });
      }
    }
    setLocalOptions(newOptions as any);
    setCboValue(value);
    props.onValueChange(value);
  };

  const formatAndSetCboValue = (value: any) => {
    if (props.multiple && !Array.isArray(value) && value.length > 0) {
      let arr: any[] = value.split("|");
      let newOptions: any[] = [...localOptions];
      for (let item of arr) {
        // @ts-ignore
        if (!localOptions.includes(item)) {
          newOptions.push({
            key: item,
            value: item,
            text: item,
          });
          setLocalOptions(newOptions as any);
        }
      }
      setCboValue(arr as any);
    } else {
      setCboValue(value);
    }
  };

  const formatValueToString = () => {
    if (props.multiple && Array.isArray(cboValue)) {
      let strValue = cboValue.join("|");
      return strValue;
    } else {
      return cboValue;
    }
  };

  return (
    <Dropdown
      id={props.id}
      lazyLoad
      loading={props.loading}
      style={{ width: props.fluid ? "100%" : "auto" }}
      className={`ComboBox ${props.className} ${props.readOnly ? "readOnly" : ""}`}
      multiple={props.multiple}
      search={props.search}
      selection={props.selection}
      disabled={props.disabled}
      clearable={props.clearable}
      options={localOptions}
      fluid={props.fluid}
      compact={props.compact}
      value={cboValue}
      error={props.error}
      placeholder={props.placeholder}
      allowAdditions={props.allowAdditions}
      minCharacters={props.minCharacters}
      noResultsMessage={props.noResultsMessage}
      onLabelClick={props.onLabelClick}
      additionLabel="เพิ่ม "
      onChange={(e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        props.onChange(e, { value });
        handleChange(e, value);
      }}
    />
  );
});

// ComboBox.propTypes = {
//   multiple: PropTypes.bool,
//   search: PropTypes.bool,
//   selection: PropTypes.bool,
//   disabled: PropTypes.bool,
//   fluid: PropTypes.bool,
//   compact: PropTypes.bool,
//   options: PropTypes.array,
//   error: PropTypes.bool,
//   minCharacters: PropTypes.number,
//   noResultsMessage: PropTypes.string,
//   placeholder: PropTypes.string,
//   onLabelClick: PropTypes.func,
//   clearable: PropTypes.bool,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
//   loading: PropTypes.bool,

//   allowAdditions: PropTypes.bool,
//   onValueChange: PropTypes.func,
//   onChange: PropTypes.func,
// };

ComboBox.defaultProps = {
  multiple: false,
  search: false,
  selection: true,
  disabled: false,
  fluid: false,
  compact: false,
  error: false,
  minCharacters: 0,
  noResultsMessage: "No results found.",
  onLabelClick: () => {},
  options: [],
  value: "",
  clearable: false,
  placeholder: "",
  loading: false,

  allowAdditions: false,
  onValueChange: () => {},
  onChange: () => {},
};

export default React.memo(ComboBox);
